import axios from "axios";

const API_URL = "https://dashboard.natindamiddleeast.com/api";
const getToken = () => localStorage.getItem("api_token");

const login = async (email, password) => {
  try {
    console.log("email", email);
    console.log("password", password);
    const response = await axios.post(`${API_URL}/loginAdmin`, {
      email: email,
      password: password,
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching user data:", error);
    throw error;
  }
};
const getUser = async () => {
  try {
    const response = await axios.get(`${API_URL}/getUser`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching user data:", error);
  }
};

const getProducts = async (hardcodedToken) => {
  try {
    const response = await axios.get(`${API_URL}/getAllProductsAdmin`, {});
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

const getSMS = async () => {
  try {
    const response = await axios.get(`${API_URL}/getSMS`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

const sendSMS = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/sendSMS`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const getDashboard = async () => {
  try {
    const response = await axios.get(`${API_URL}/getDashboard`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    // console.log("data:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};
const getOrders = async () => {
  try {
    const response = await axios.get(`${API_URL}/getOrders`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    // console.log("data:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

const statusLabelToValue = {
  Pending: "1",
  Approved: "2",
  Shipped: "3",
  Received: "4",
  Rejected: "5",
  "Returned Damaged": "6",
  Returned: "7",
};
const updateOrder = async (data) => {
  const mappedData = { ...data, status: statusLabelToValue[data.status] };
  try {
    const response = await axios.put(
      `${API_URL}/updateOrder/${data.id}`,
      mappedData,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating order data:", error);
    throw error;
  }
};
const removeOrder = async (id) => {
  try {
    const response = await axios.post(
      `${API_URL}/removeOrder/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error removing order:", error);
    throw error;
  }
};
const removeOrders = async (data) => {
  console.log("Delete orders:", data);

  try {
    const response = await axios.post(`${API_URL}/removeOrders/`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating order data:", error);
    throw error;
  }
};
const addOrder = async (data) => {
  console.log("order", data);
  try {
    const response = await axios.post(`${API_URL}/addOrder`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    console.log("addOrder:", response.data);

    return response.data;
  } catch (error) {
    console.error("Error updating order data:", error);
    throw error;
  }
};

const getUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}/getUsers`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

const removeUser = async (id) => {
  try {
    const response = await axios.post(
      `${API_URL}/removeUser/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error removing user:", error);
    throw error;
  }
};

const removeUsers = async (data) => {
  console.log("Delete Users:", data);

  try {
    const response = await axios.post(`${API_URL}/removeUsers/`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating user data:", error);
    throw error;
  }
};
const updateUser = async (data) => {
  const mappedData = {
    ...data,
    accept_promotion: data.accept_promotion === "No" ? 0 : 1,
  };
  try {
    const response = await axios.put(
      `${API_URL}/updateUser/${data.id}`,
      mappedData,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("updating user data:", response);
    return response.data;
  } catch (error) {
    console.error("Error updating user data:", error);
    throw error;
  }
};

const addUser = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/addUser`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    console.log("addUser:", response.data);

    return response.data;
  } catch (error) {
    console.error("Error updating user data:", error);
    throw error;
  }
};

const getPromoCodes = async () => {
  try {
    const response = await axios.get(`${API_URL}/getPromoCodes`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};
const removePromoCode = async (id) => {
  try {
    const response = await axios.post(
      `${API_URL}/removePromoCode/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error removing user:", error);
    throw error;
  }
};

const removePromoCodes = async (data) => {
  console.log("Delete PromoCodes:", data);
  try {
    const response = await axios.post(`${API_URL}/removePromoCodes/`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error deleting promo codes:", error);
    throw error;
  }
};
const updatePromoCode = async (data) => {
  const mappedData = {
    ...data,
    is_working: data.is_working === "Working" ? 1 : 2,
  };

  console.log("Submitting edited data:", mappedData);

  try {
    const response = await axios.put(
      `${API_URL}/updatePromoCode/${data.id}`,
      mappedData,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating user data:", error);
    throw error;
  }
};

const addPromoCode = async (data) => {
  try {
    const mappedData = {
      ...data,
      is_working: data.is_working === "Working" ? 1 : 2,
    };

    const response = await axios.post(`${API_URL}/addPromoCode`, mappedData, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });

    console.log("addPromoCode:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating promo code data:", error);
    throw error;
  }
};

const getReviews = async () => {
  try {
    const response = await axios.get(`${API_URL}/getReviews`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    // console.log("data:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching reviews data:", error);
    throw error;
  }
};

const removeReview = async (id) => {
  try {
    const response = await axios.post(
      `${API_URL}/removeReview/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error removing Review:", error);
    throw error;
  }
};

const removeReviews = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/removeReviews/`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating review data:", error);
    throw error;
  }
};
const updateReview = async (data) => {
  const mappedData = {
    ...data,
    would_recommend: data.would_recommend === "No" ? 0 : 1,
  };

  console.log("mappedData", mappedData);

  try {
    const response = await axios.put(
      `${API_URL}/updateReview/${data.id}`,
      mappedData,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating review data:", error);
    throw error;
  }
};

const getFAQ = async () => {
  try {
    const response = await axios.get(`${API_URL}/getFAQ`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
const removeFAQ = async (id) => {
  try {
    const response = await axios.post(
      `${API_URL}/removeFAQ/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const removeFAQs = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/removeFAQs/`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
const updateFAQ = async (data) => {
  try {
    const response = await axios.put(`${API_URL}/updateFAQ/${data.id}`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
const addFAQ = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/addFAQ`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getBlogs = async () => {
  try {
    const response = await axios.get(`${API_URL}/getBlogs`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
const removeBlog = async (id) => {
  try {
    const response = await axios.post(
      `${API_URL}/removeBlog/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const removeBlogs = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/removeBlogs/`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
const updateBlog = async (data) => {
  try {
    const response = await axios.put(`${API_URL}/updateBlog/${data.id}`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
const addBlog = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/addBlog`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getProductTypes = async () => {
  try {
    const response = await axios.get(`${API_URL}/getProductTypes`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const removeProductType = async (id) => {
  try {
    const response = await axios.post(
      `${API_URL}/removeProductType/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const removeProductTypes = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/removeProductTypes/`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
const updateProductType = async (data) => {
  try {
    const response = await axios.put(
      `${API_URL}/updateProductType/${data.id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const addProductType = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/addProductType`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getCarts = async () => {
  try {
    const response = await axios.get(`${API_URL}/getCarts`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
const removeCart = async (id) => {
  try {
    const response = await axios.post(
      `${API_URL}/removeCart/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const removeCarts = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/removeCarts/`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getShippingAddresses = async () => {
  try {
    const response = await axios.get(`${API_URL}/getShippingAddresses`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
const removeShippingAddress = async (id) => {
  try {
    const response = await axios.post(
      `${API_URL}/removeShippingAddress/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const removeShippingAddresses = async (data) => {
  try {
    const response = await axios.post(
      `${API_URL}/removeShippingAddresses/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {
  getUser,
  getProducts,
  login,
  getOrders,
  updateOrder,
  removeOrder,
  removeOrders,
  addOrder,
  getUsers,
  removeUser,
  removeUsers,
  updateUser,
  addUser,
  getPromoCodes,
  removePromoCode,
  removePromoCodes,
  updatePromoCode,
  addPromoCode,
  getReviews,
  removeReview,
  removeReviews,
  updateReview,
  getFAQ,
  removeFAQ,
  removeFAQs,
  updateFAQ,
  addFAQ,
  getBlogs,
  removeBlog,
  removeBlogs,
  updateBlog,
  addBlog,
  getProductTypes,
  removeProductType,
  removeProductTypes,
  updateProductType,
  addProductType,
  getCarts,
  removeCart,
  removeCarts,
  getShippingAddresses,
  removeShippingAddress,
  removeShippingAddresses,
  getDashboard,
  getSMS,
  sendSMS,
};

export default api;
