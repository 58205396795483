import React, { useState, useEffect } from "react";
import Loader from "react-js-loader";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import api from "state/api";
import "./index.css";
import { LineChart } from "@mui/x-charts/LineChart";
import { Chart } from "react-google-charts";

const chartSetting = {
  yAxis: [{ label: "Number of Carts Per Week" }],
  width: 800,
  height: 600,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "translate(-10px, 0)",
    },
  },
};

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [countryData, setCountryData] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await api.getDashboard();
        const formattedData = [["Country", "Order Count"]];
        response.ordersByCountry.forEach((item) => {
          formattedData.push([item.country, item.order_count]);
        });

        setCountryData(formattedData);
        setDashboardData(response);
      } catch (error) {
        console.log("Error fetching dashboard data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  const extractChartData = (ordersPerWeek) => {
    return {
      weeks: ordersPerWeek.map((entry) => entry.week_start_date),
      counts: ordersPerWeek.map((entry) => entry.count),
    };
  };

  const transformCartsPerWeekData = (cartsPerWeek) => {
    return cartsPerWeek.map(({ week_start_date, count }) => ({
      week_start_date,
      count,
    }));
  };

  const options = {
    displayMode: "regions",
    colorAxis: { colors: ["#e0f7fa", "#997d3d"] },
    resolution: "countries",
    region: "145",
    backgroundColor: "#f5f5f5",
    datalessRegionColor: "#f5f5f5",
  };

  if (loading) {
    return (
      <div className="loader-container">
        <Loader type="spinner-cub" bgColor="gold" color="gold" size={100} />
      </div>
    );
  }

  const { weeks, counts } = extractChartData(dashboardData.ordersPerWeek);
  const totalProducts = dashboardData.totalProductQuantities;
  const productQuantities = dashboardData.productQuantities.map(
    (product, index) => ({
      id: index,
      value: parseInt(product.total_quantity, 10),
      label: product.name_en,
      color: product.theme,
    })
  );
  const orderQuantities = dashboardData.orderQuantities.map(
    (product, index) => ({
      id: index,
      value: parseInt(product.total_quantity, 10),
      label: product.name_en,
      color: product.theme,
    })
  );

  return (
    <div className="dashboard-container">
      <div className="dashboard-grid">
        <div className="dashboard-card">
          <h3>Users</h3>
          <p>{dashboardData.user}</p>
        </div>
        <div className="dashboard-card">
          <h3>Carts</h3>
          <p>{dashboardData.cart}</p>
        </div>
        <div className="dashboard-card">
          <h3>Orders</h3>
          <p>{dashboardData.order}</p>
        </div>
        <div className="dashboard-card-mui"> </div>
        <div className="dashboard-card-mui">
          <h2>Order by Week</h2>
          <LineChart
            xAxis={[
              { data: weeks, scaleType: "band", label: "Week Start Date" },
            ]}
            series={[{ data: counts }]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontWeight: "bold",
                fontSize: "14px",
              },
              [`& .${axisClasses.bottom} .${axisClasses.label}`]: {
                fill: "white",
                fontWeight: "bold",
                fontSize: "14px",
                transform: "translateY(20px)",
              },
              [`& .${axisClasses.left} .${axisClasses.label}`]: {
                fill: "white",
                fontWeight: "bold",
                fontSize: "14px",
                transform: "translate(-10px, 0)",
              },
              [`& .${axisClasses.tick}`]: {
                fill: "white",
                fontWeight: "bold",
              },
            }}
            height={400}
          />
        </div>
        <div className="dashboard-card-mui">
          <h2>Product Quantities in Carts</h2>
          <h3>Total number of products across all carts: {totalProducts}</h3>
          <PieChart
            series={[
              {
                data: productQuantities,
                highlightScope: { faded: "global", highlighted: "item" },
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontWeight: "bold",
              },
            }}
            height={400}
          />
        </div>
        <div className="dashboard-card-mui">
          <h2>Product Quantities in Orders</h2>
          <PieChart
            series={[
              {
                data: orderQuantities,
                highlightScope: { faded: "global", highlighted: "item" },
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontWeight: "bold",
              },
            }}
            height={400}
          />
        </div>
        <Chart
          chartType="GeoChart"
          data={countryData}
          options={options}
          width="900px"
          height="500px"
        />
        <div className="dashboard-card-mui">
          <h2>Number of Carts Over Time</h2>
          <BarChart
            dataset={transformCartsPerWeekData(dashboardData.cartsPerWeek)}
            xAxis={[{ scaleType: "band", dataKey: "week_start_date" }]}
            series={[{ dataKey: "count", label: "Count" }]}
            {...chartSetting}
          />
        </div>
      </div>
      <p className="footer-text">
        Developed and operated by Omar Halim and Zeyad Salah.
      </p>
    </div>
  );
};

export default Dashboard;
