import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Switch,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FormControlLabel from "@mui/material/FormControlLabel";

const AddOrderDialog = ({
  isOpen,
  onClose,
  onSubmit,
  users,
  shippingAddresses,
  promoCodes,
  products,
}) => {
  const [newOrderData, setNewOrderData] = useState({
    user_id: "",
    shipping_address_id: "",
    promocode_id: "",
    is_paid: "",
    status: "",
    selectedProducts: [],
  });
  const [productQuantities, setProductQuantities] = useState({});
  const [highlightedProducts, setHighlightedProducts] = useState([]);
  const [useUserPoints, setUseUserPoints] = useState(false);
  const [deliveryFees, setDeliveryFees] = useState(true);
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);
  const [newAddress, setNewAddress] = useState({
    first_name: "",
    last_name: "",
    address: "",
    phone: "",
    selectedCountry: "",
    selectedCity: "",
    email: "",
  });
  const [countryOptions] = useState([
    { value: "SA", label: "Saudi Arabia" },
    { value: "QA", label: "Qatar" },
    { value: "BH", label: "bahrain" },
    { value: "OM", label: "Oman" },
    { value: "AE", label: "United Arab Emirates" },
    { value: "KW", label: "Kuwait" },
  ]);
  const cityOptionsByCountry = {
    SA: [
      { value: "Abha", label: "Abha" },
      { value: "Ad Dilam", label: "Ad Dilam" },
      { value: "Ad Diriyah", label: "Ad Diriyah" },
      { value: "Afif", label: "Afif" },
      { value: "Ahad Al Musarihah", label: "Ahad Al Musarihah" },
      { value: "Ahad Rafidah", label: "Ahad Rafidah" },
      { value: "Al Aflaj (Layla)", label: "Al Aflaj (Layla)" },
      { value: "Al Badai", label: "Al Badai" },
      { value: "Al Bashayer", label: "Al Bashayer" },
      { value: "Al Baha", label: "Al Baha" },
      { value: "Al Bukayriyah", label: "Al Bukayriyah" },
      { value: "Al Ghat", label: "Al Ghat" },
      { value: "Al Hariq", label: "Al Hariq" },
      { value: "Al Hofuf", label: "Al Hofuf" },
      { value: "Al Jubail", label: "Al Jubail" },
      { value: "Al Jumum", label: "Al Jumum" },
      { value: "Al Kharj", label: "Al Kharj" },
      { value: "Al Khafji", label: "Al Khafji" },
      { value: "Al Khubar", label: "Al Khubar" },
      { value: "Al Khurmah", label: "Al Khurmah" },
      { value: "Al Majardah", label: "Al Majardah" },
      { value: "Al Majma'ah", label: "Al Majma'ah" },
      { value: "Al Midhnab", label: "Al Midhnab" },
      { value: "Al Muzahimiyah", label: "Al Muzahimiyah" },
      { value: "Al Qatif", label: "Al Qatif" },
      { value: "Al Qurayyat", label: "Al Qurayyat" },
      { value: "Al Wajh", label: "Al Wajh" },
      { value: "Al-Dawadmi", label: "Al-Dawadmi" },
      { value: "An Nuayriyah", label: "An Nuayriyah" },
      { value: "Ar Rass", label: "Ar Rass" },
      { value: "Arar", label: "Arar" },
      { value: "As Sulayyil", label: "As Sulayyil" },
      { value: "Ash Shinan", label: "Ash Shinan" },
      { value: "Ash Shimasiyah", label: "Ash Shimasiyah" },
      { value: "Aqiq", label: "Aqiq" },
      { value: "Az Zulfi", label: "Az Zulfi" },
      { value: "Badr", label: "Badr" },
      { value: "Billasmar", label: "Billasmar" },
      { value: "Bishah", label: "Bishah" },
      { value: "Buqayq", label: "Buqayq" },
      { value: "Buraydah", label: "Buraydah" },
      { value: "Dammam", label: "Dammam" },
      { value: "Dawmat Al Jandal", label: "Dawmat Al Jandal" },
      { value: "Dhahran", label: "Dhahran" },
      { value: "Dhurma", label: "Dhurma" },
      { value: "Duba", label: "Duba" },
      { value: "Hafar al-Batin", label: "Hafar al-Batin" },
      { value: "Haqil", label: "Haqil" },
      { value: "Hail", label: "Hail" },
      { value: "Hawtat Bani Tamim", label: "Hawtat Bani Tamim" },
      { value: "Inak", label: "Inak" },
      { value: "Jazan", label: "Jazan" },
      { value: "Jeddah", label: "Jeddah" },
      { value: "Jouf", label: "Jouf" },
      { value: "Khamis Mushait", label: "Khamis Mushait" },
      { value: "Khaybar", label: "Khaybar" },
      { value: "Khulais", label: "Khulais" },
      {
        value: "King Abdullah Economic City",
        label: "King Abdullah Economic City",
      },
      { value: "Laith", label: "Laith" },
      { value: "Layla", label: "Layla" },
      { value: "Madinah", label: "Madinah" },
      { value: "Makkah", label: "Makkah" },
      { value: "Muhayil Aseer", label: "Muhayil Aseer" },
      { value: "Mudhaylif", label: "Mudhaylif" },
      { value: "Najran", label: "Najran" },
      { value: "Qaryat Al Ulya", label: "Qaryat Al Ulya" },
      { value: "Qassim", label: "Qassim" },
      { value: "Quwayiyah", label: "Quwayiyah" },
      { value: "Rafha", label: "Rafha" },
      { value: "Rania", label: "Rania" },
      { value: "Ras Tannurah", label: "Ras Tannurah" },
      { value: "Riyad Al Khabra", label: "Riyad Al Khabra" },
      { value: "Riyadh", label: "Riyadh" },
      { value: "Rabigh", label: "Rabigh" },
      { value: "Sabya", label: "Sabya" },
      { value: "Safwa", label: "Safwa" },
      { value: "Sakaka", label: "Sakaka" },
      { value: "Samtah", label: "Samtah" },
      { value: "Sayhat", label: "Sayhat" },
      { value: "Sharurah", label: "Sharurah" },
      { value: "Shaqra", label: "Shaqra" },
      { value: "Shraiee", label: "Shraiee" },
      { value: "Sakaka", label: "Sakaka" },
      { value: "Tabuk", label: "Tabuk" },
      { value: "Taif", label: "Taif" },
      { value: "Tathilith", label: "Tathilith" },
      { value: "Tarut", label: "Tarut" },
      { value: "Thadiq", label: "Thadiq" },
      { value: "Thuwal", label: "Thuwal" },
      { value: "Turaif", label: "Turaif" },
      { value: "Turaib", label: "Turaib" },
      { value: "Turbah", label: "Turbah" },
      { value: "Umluj", label: "Umluj" },
      { value: "Unaizah", label: "Unaizah" },
      { value: "Uqlat Al Suqur", label: "Uqlat Al Suqur" },
      { value: "Wadi El Dwaser", label: "Wadi El Dwaser" },
      { value: "Yanbu", label: "Yanbu" },
      { value: "Zalal", label: "Zalal" },
    ],
    QA: [
      { value: "Al Khor", label: "Al Khor" },
      { value: "AL SHAMAL", label: "AL SHAMAL" },
      { value: "AL UDEID", label: "AL UDEID" },
      { value: "DOHA", label: "DOHA" },
      { value: "DOHA ABU NAKLA", label: "DOHA ABU NAKLA" },
      { value: "DOHA ABU SIDRA", label: "DOHA ABU SIDRA" },
      { value: "DOHA AL DAFNA", label: "DOHA AL DAFNA" },
      { value: "DOHA AL DAYEEN", label: "DOHA AL DAYEEN" },
      { value: "DOHA AL DUHAIL", label: "DOHA AL DUHAIL" },
      { value: "DOHA AL GHARAFA", label: "DOHA AL GHARAFA" },
      { value: "DOHA AL GHUWAIRIYA", label: "DOHA AL GHUWAIRIYA" },
      { value: "DOHA AL HILAL", label: "DOHA AL HILAL" },
      { value: "DOHA AL KEESHA", label: "DOHA AL KEESHA" },
      { value: "DOHA AL KHARATIYAT", label: "DOHA AL KHARATIYAT" },
      { value: "DOHA AL KHULAIFAT", label: "DOHA AL KHULAIFAT" },
      { value: "DOHA AL KHUWAIR", label: "DOHA AL KHUWAIR" },
      { value: "DOHA AL LUQTA", label: "DOHA AL LUQTA" },
      { value: "DOHA AL MESSILA", label: "DOHA AL MESSILA" },
      { value: "DOHA AL RUWAIS", label: "DOHA AL RUWAIS" },
      { value: "DOHA AL SAAD", label: "DOHA AL SAAD" },
      { value: "DOHA AL SAILIYA", label: "DOHA AL SAILIYA" },
      { value: "DOHA AL SHAKAMA", label: "DOHA AL SHAKAMA" },
      { value: "DOHA AL THAKHIRA", label: "DOHA AL THAKHIRA" },
      { value: "DOHA AL WAAB", label: "DOHA AL WAAB" },
      { value: "DOHA AL WAJBA", label: "DOHA AL WAJBA" },
      { value: "DOHA AL WAKRA", label: "DOHA AL WAKRA" },
      { value: "DOHA ALMANSOURA", label: "DOHA ALMANSOURA" },
      { value: "DOHA AZIZYA", label: "DOHA AZIZYA" },
      { value: "DOHA BANI HAJER", label: "DOHA BANI HAJER" },
      { value: "DOHA BIN MAHMOUD", label: "DOHA BIN MAHMOUD" },
      { value: "DOHA BUHAMOUR", label: "DOHA BUHAMOUR" },
      { value: "DOHA DAHL AL HAMAM", label: "DOHA DAHL AL HAMAM" },
      { value: "DOHA FAREEJ AL AMIR", label: "DOHA FAREEJ AL AMIR" },
      { value: "DOHA FEREEJ AL AMIR", label: "DOHA FEREEJ AL AMIR" },
      { value: "DOHA FEREEJ AL KULAIB", label: "DOHA FEREEJ AL KULAIB" },
      { value: "DOHA FEREEJ AL NASR", label: "DOHA FEREEJ AL NASR" },
      { value: "DOHA FEREEJ BIN DERHAM", label: "DOHA FEREEJ BIN DERHAM" },
      { value: "DOHA GHARRFA", label: "DOHA GHARRFA" },
      { value: "DOHA HAMAD INTL AIRPORT", label: "DOHA HAMAD INTL AIRPORT" },
      { value: "DOHA HAZM AL MARKHIYA", label: "DOHA HAZM AL MARKHIYA" },
      { value: "DOHA INDUSTRIAL AREA", label: "DOHA INDUSTRIAL AREA" },
      { value: "DOHA IZGHAWA", label: "DOHA IZGHAWA" },
      { value: "DOHA JEBAILAT", label: "DOHA JEBAILAT" },
      { value: "DOHA JELAIAH", label: "DOHA JELAIAH" },
      { value: "DOHA JEMALIYA", label: "DOHA JEMALIYA" },
      { value: "DOHA KHULAIFATH", label: "DOHA KHULAIFATH" },
      { value: "DOHA LUSAIL", label: "DOHA LUSAIL" },
      { value: "DOHA MADHINAT KHALIFA", label: "DOHA MADHINAT KHALIFA" },
      { value: "DOHA MAMOURA", label: "DOHA MAMOURA" },
      { value: "DOHA MANASEER", label: "DOHA MANASEER" },
      { value: "DOHA MARKHAYA", label: "DOHA MARKHAYA" },
      { value: "DOHA MESAIEED", label: "DOHA MESAIEED" },
      { value: "DOHA MESAIMEER", label: "DOHA MESAIMEER" },
      { value: "DOHA MESSILA", label: "DOHA MESSILA" },
      { value: "DOHA MUAITHER", label: "DOHA MUAITHER" },
      { value: "DOHA MUSHERIEB", label: "DOHA MUSHERIEB" },
      { value: "DOHA NAJMA", label: "DOHA NAJMA" },
      { value: "DOHA NEW AL RAYYAN", label: "DOHA NEW AL RAYYAN" },
      { value: "DOHA NEW INDUSTRIAL AREA", label: "DOHA NEW INDUSTRIAL AREA" },
      { value: "DOHA NEW SALATA", label: "DOHA NEW SALATA" },
      { value: "DOHA NUAIJA", label: "DOHA NUAIJA" },
      { value: "DOHA OLD AIRPORT", label: "DOHA OLD AIRPORT" },
      { value: "DOHA OLD AL RAYYAN", label: "DOHA OLD AL RAYYAN" },
      { value: "DOHA ONAIZA", label: "DOHA ONAIZA" },
      { value: "DOHA PEARL QATAR", label: "DOHA PEARL QATAR" },
      { value: "DOHA RAS ABU ABBOUD", label: "DOHA RAS ABU ABBOUD" },
      { value: "DOHA RAS ABU FONTAS", label: "DOHA RAS ABU FONTAS" },
      { value: "DOHA RAWDA AL - KHAIL", label: "DOHA RAWDA AL - KHAIL" },
      { value: "DOHA RAWDAT RASHEED", label: "DOHA RAWDAT RASHEED" },
      { value: "DOHA RAWDATH AL HAMMAMA", label: "DOHA RAWDATH AL HAMMAMA" },
      { value: "DOHA RUMAILA", label: "DOHA RUMAILA" },
      { value: "DOHA SAHA", label: "DOHA SAHA" },
      { value: "DOHA SHAHANIYA", label: "DOHA SHAHANIYA" },
      { value: "DOHA SHAMAL", label: "DOHA SHAMAL" },
      { value: "DOHA SPORTS CITY", label: "DOHA SPORTS CITY" },
      { value: "DOHA THUMAMA", label: "DOHA THUMAMA" },
      { value: "DOHA UMM AL AMAD", label: "DOHA UMM AL AMAD" },
      { value: "DOHA UMM AL SANEEM", label: "DOHA UMM AL SANEEM" },
      { value: "DOHA UMM BAB", label: "DOHA UMM BAB" },
      { value: "DOHA UMM GARN", label: "DOHA UMM GARN" },
      { value: "DOHA UMM GHUWAILINA", label: "DOHA UMM GHUWAILINA" },
      { value: "DOHA UMM LEKHBA", label: "DOHA UMM LEKHBA" },
      { value: "DOHA UMM SAID", label: "DOHA UMM SAID" },
      { value: "DOHA UMM SLAL ALI", label: "DOHA UMM SLAL ALI" },
      { value: "DOHA UMM SLAL MOHAMMAD", label: "DOHA UMM SLAL MOHAMMAD" },
      { value: "DOHA WADI AL BANAT", label: "DOHA WADI AL BANAT" },
      { value: "DOHA WESTBAY", label: "DOHA WESTBAY" },
      { value: "DOHA WUKAIR", label: "DOHA WUKAIR" },
      { value: "DUKHAN", label: "DUKHAN" },
      { value: "DUKHAN AL NASRANIYA", label: "DUKHAN AL NASRANIYA" },
      {
        value: "DUKHAN ZEKREET INTERCHANGE",
        label: "DUKHAN ZEKREET INTERCHANGE",
      },
      { value: "RAS LAFFAN", label: "RAS LAFFAN" },
      {
        value: "RAS LAFFAN INDUSTRIAL CITY",
        label: "RAS LAFFAN INDUSTRIAL CITY",
      },
      { value: "Al Adhbah", label: "Al Adhbah" },
      { value: "Al Hitmi", label: "Al Hitmi" },
      { value: "Al Jasrah", label: "Al Jasrah" },
      { value: "Al Jumaliyah", label: "Al Jumaliyah" },
      { value: "Al Daayen", label: "Al Daayen" },
      { value: "Al Ka`biyah", label: "Al Ka`biyah" },
      { value: "Al Khalifat", label: "Al Khalifat" },
    ],
    BH: [
      { value: "Capital Governorate", label: "Capital Governorate" },
      { value: "Muharraq Governorate", label: "Muharraq Governorate" },
      { value: "Northern Governorate", label: "Northern Governorate" },
      { value: "Southern Governorate", label: "Southern Governorate" },
      { value: "AALI", label: "AALI" },
      { value: "ADARI", label: "ADARI" },
      { value: "ADLIYA", label: "ADLIYA" },
      { value: "AL DUR", label: "AL DUR" },
      { value: "AL EKER", label: "AL EKER" },
      { value: "AL HAJAR", label: "AL HAJAR" },
      { value: "AL HAMALAH", label: "AL HAMALAH" },
      { value: "AL MARKH", label: "AL MARKH" },
      { value: "AL SALMANIYAH", label: "AL SALMANIYAH" },
      { value: "AL-HIDD", label: "AL-HIDD" },
      { value: "AMWAJ", label: "AMWAJ" },
      { value: "ASKAR", label: "ASKAR" },
      { value: "AWALI", label: "AWALI" },
      { value: "BARBAR", label: "BARBAR" },
      { value: "BUDAIYA", label: "BUDAIYA" },
      { value: "DIPLOMATIC AREA", label: "DIPLOMATIC AREA" },
      { value: "DURT AL BAHRAIN", label: "DURT AL BAHRAIN" },
      { value: "HAMAD TOWN", label: "HAMAD TOWN" },
      { value: "ISA TOWN", label: "ISA TOWN" },
      { value: "JANABIYAH", label: "JANABIYAH" },
      { value: "JANUSAN", label: "JANUSAN" },
      { value: "MAAMMER", label: "MAAMMER" },
      { value: "MANAMA", label: "MANAMA" },
      { value: "MUHARRAQ", label: "MUHARRAQ" },
      { value: "QALALI", label: "QALALI" },
      { value: "RIFFA", label: "RIFFA" },
      { value: "RIFFA VIEWS", label: "RIFFA VIEWS" },
      { value: "RLC", label: "RLC" },
      { value: "SAAR", label: "SAAR" },
      { value: "SAKHIR", label: "SAKHIR" },
      { value: "SALMABAD", label: "SALMABAD" },
      { value: "SANABIS", label: "SANABIS" },
      { value: "SANAD", label: "SANAD" },
      { value: "SEEF", label: "SEEF" },
      { value: "SITRA", label: "SITRA" },
      { value: "TASHAN", label: "TASHAN" },
      { value: "ZALAQ", label: "ZALAQ" },
      { value: "Dumistan", label: "Dumistan" },
      { value: "Tubli", label: "Tubli" },
      { value: "Buri", label: "Buri" },
      { value: "Diraz", label: "Diraz" },
      { value: "Bani Jamra", label: "Bani Jamra" },
      { value: "Jordab", label: "Jordab" },
      { value: "Arad", label: "Arad" },
      { value: "Samaheej", label: "Samaheej" },
      { value: "Buquwa", label: "Buquwa" },
      { value: "Jidhafs", label: "Jidhafs" },
      { value: "Abu Beham", label: "Abu Beham" },
      { value: "Abu Saiba", label: "Abu Saiba" },
      { value: "Busaiteen", label: "Busaiteen" },
      { value: "Dair", label: "Dair" },
      { value: "Dar Kulaib", label: "Dar Kulaib" },
      { value: "Ghurayfah", label: "Ghurayfah" },
      { value: "Hillat Abdulsaleh", label: "Hillat Abdulsaleh" },
      { value: "Jasra", label: "Jasra" },
      { value: "Jiblat Habshi", label: "Jiblat Habshi" },
      { value: "Jid Alhaj", label: "Jid Alhaj" },
      { value: "Juffair", label: "Juffair" },
      { value: "Karbabad", label: "Karbabad" },
      { value: "Karrana", label: "Karrana" },
      { value: "Karzakan", label: "Karzakan" },
      { value: "Khamis", label: "Khamis" },
      { value: "Malikiya", label: "Malikiya" },
      { value: "Meqsha", label: "Meqsha" },
      { value: "Mina Salman", label: "Mina Salman" },
      { value: "Muqaba", label: "Muqaba" },
      { value: "Musalla", label: "Musalla" },
      { value: "Nasfah", label: "Nasfah" },
      { value: "North Sehla", label: "North Sehla" },
      { value: "Qadam", label: "Qadam" },
      { value: "Qella", label: "Qella" },
      { value: "Qofool", label: "Qofool" },
      { value: "Qudaibiya", label: "Qudaibiya" },
      { value: "Qurayya", label: "Qurayya" },
      { value: "Ras Ruman", label: "Ras Ruman" },
      { value: "Sadad", label: "Sadad" },
      { value: "Shahrakan", label: "Shahrakan" },
      { value: "Shakhura", label: "Shakhura" },
      { value: "South Sehla", label: "South Sehla" },
      { value: "Tala Island", label: "Tala Island" },
      { value: "Um Alhassam", label: "Um Alhassam" },
      {
        value: "Umm Alsabban (Mohammadiya)",
        label: "Umm Alsabban (Mohammadiya)",
      },
      { value: "Zayed Town", label: "Zayed Town" },
      { value: "Jannusan", label: "Jannusan" },
      { value: "Almazrowiah", label: "Almazrowiah" },
      { value: "Habsab", label: "Habsab" },
    ],
    OM: [
      { value: "AL ANSAB", label: "AL ANSAB" },
      { value: "AL KHOUD", label: "AL KHOUD" },
      { value: "AL KHUWAIR", label: "AL KHUWAIR" },
      { value: "AZAIBA", label: "AZAIBA" },
      { value: "BARKA", label: "BARKA" },
      { value: "BOSHAR", label: "BOSHAR" },
      { value: "CBD AREA", label: "CBD AREA" },
      { value: "DARSAIT", label: "DARSAIT" },
      { value: "GHALA", label: "GHALA" },
      { value: "GHUBRAH", label: "GHUBRAH" },
      { value: "HAMRIYA", label: "HAMRIYA" },
      { value: "JIBROO", label: "JIBROO" },
      { value: "MABELA", label: "MABELA" },
      { value: "MADINAT AL ALAM", label: "MADINAT AL ALAM" },
      { value: "MADINAT QABOOS", label: "MADINAT QABOOS" },
      { value: "MARBAT", label: "MARBAT" },
      { value: "MEDINAT QABOOS", label: "MEDINAT QABOOS" },
      { value: "MINA AL FAHAL", label: "MINA AL FAHAL" },
      { value: "MINISTRY AREA", label: "MINISTRY AREA" },
      { value: "Muscat", label: "Muscat" },
      { value: "MUTTRAH", label: "MUTTRAH" },
      { value: "MUWALAH", label: "MUWALAH" },
      { value: "QURUM", label: "QURUM" },
      { value: "QURUM HEIGHTS", label: "QURUM HEIGHTS" },
      { value: "RAYSUT", label: "RAYSUT" },
      { value: "RUSAIL", label: "RUSAIL" },
      { value: "RUWI", label: "RUWI" },
      { value: "SALALAH", label: "SALALAH" },
      { value: "SEEB", label: "SEEB" },
      { value: "SHATTI QURUM", label: "SHATTI QURUM" },
      { value: "SOHAR", label: "SOHAR" },
      { value: "THUMRAIT", label: "THUMRAIT" },
      { value: "WADI ADAI", label: "WADI ADAI" },
      { value: "WADI KEBIR", label: "WADI KEBIR" },
      { value: "WATTAYAH", label: "WATTAYAH" },
      { value: "Sur", label: "Sur" },
      { value: "Suwaiq", label: "Suwaiq" },
      { value: "Ibri", label: "Ibri" },
      { value: "BURAIMI", label: "BURAIMI" },
      { value: "rustaq", label: "rustaq" },
      { value: "Nizwa", label: "Nizwa" },
      { value: "Khabourah", label: "Khabourah" },
      { value: "IBRA", label: "IBRA" },
      { value: "Mawaleh", label: "Mawaleh" },
      { value: "Khasab", label: "Khasab" },
      { value: "Adam", label: "Adam" },
      { value: "Al Aqir", label: "Al Aqir" },
      { value: "Al Ashkarah", label: "Al Ashkarah" },
      { value: "Al Bidayah", label: "Al Bidayah" },
      { value: "Al Duqm", label: "Al Duqm" },
      { value: "Al Ghazir", label: "Al Ghazir" },
      { value: "Al Hamra", label: "Al Hamra" },
      { value: "Al Jadida", label: "Al Jadida" },
      { value: "Al Kabil", label: "Al Kabil" },
      { value: "Al Kamil Wal Wafi", label: "Al Kamil Wal Wafi" },
      { value: "Al Mazyunah", label: "Al Mazyunah" },
      { value: "Al Mudhaibi", label: "Al Mudhaibi" },
      { value: "Al Saada", label: "Al Saada" },
      { value: "Amerat", label: "Amerat" },
      { value: "As Sulaif", label: "As Sulaif" },
      { value: "Bahla", label: "Bahla" },
      { value: "Bidbid", label: "Bidbid" },
      { value: "Bidiya", label: "Bidiya" },
      { value: "Bisiyah", label: "Bisiyah" },
      { value: "Bukha", label: "Bukha" },
      { value: "Dhalkut", label: "Dhalkut" },
      { value: "Dhank", label: "Dhank" },
      { value: "Diba", label: "Diba" },
      { value: "Dima Wattayeen", label: "Dima Wattayeen" },
      { value: "Fahud", label: "Fahud" },
      { value: "Falaj Al Qabail", label: "Falaj Al Qabail" },
      { value: "Fanja", label: "Fanja" },
      { value: "Firq", label: "Firq" },
      { value: "Haima", label: "Haima" },
      { value: "Izki", label: "Izki" },
      { value: "Jaalan Bani Bu Ali", label: "Jaalan Bani Bu Ali" },
      { value: "Jaalan ni Bu Hassan", label: "Jaalan ni Bu Hassan" },
      { value: "Jabal Akhdar", label: "Jabal Akhdar" },
      { value: "Liwa", label: "Liwa" },
      { value: "Madha", label: "Madha" },
      { value: "Mahdha", label: "Mahdha" },
      { value: "Mahout", label: "Mahout" },
      { value: "Manah", label: "Manah" },
      { value: "Marmul", label: "Marmul" },
      { value: "Masirah", label: "Masirah" },
      { value: "Musannah", label: "Musannah" },
      { value: "Nakhl", label: "Nakhl" },
      { value: "Qatana", label: "Qatana" },
      { value: "Qurayat", label: "Qurayat" },
      { value: "Sadah", label: "Sadah" },
      { value: "Saham", label: "Saham" },
      { value: "Samad Al Shan", label: "Samad Al Shan" },
      { value: "Samayil", label: "Samayil" },
      { value: "Shinas", label: "Shinas" },
      { value: "Sidab", label: "Sidab" },
      { value: "Sinaw", label: "Sinaw" },
      { value: "Sunaina", label: "Sunaina" },
      { value: "Tiwi", label: "Tiwi" },
      { value: "Wadi al Maawil", label: "Wadi al Maawil" },
      { value: "Wadi Bani Khalid", label: "Wadi Bani Khalid" },
    ],
    AE: [
      { value: "Dubai", label: "Dubai" },
      { value: "ABU DHABI CITY", label: "ABU DHABI CITY" },
      { value: "ABU HAIL", label: "ABU HAIL" },
      { value: "AFLAJ AL AIN", label: "AFLAJ AL AIN" },
      { value: "AJMAN CITY", label: "AJMAN CITY" },
      { value: "AL AAHAD", label: "AL AAHAD" },
      { value: "AL ABRAQ", label: "AL ABRAQ" },
      { value: "AL AIN AIRPORT", label: "AL AIN AIRPORT" },
      { value: "AL AIN", label: "AL AIN" },
      { value: "AL AIN DAIRY FARM AL AIN", label: "AL AIN DAIRY FARM AL AIN" },
      { value: "AL ANDALUS COMPOUND", label: "AL ANDALUS COMPOUND" },
      { value: "AL AQAA", label: "AL AQAA" },
      { value: "AL ATTAIN", label: "AL ATTAIN" },
      { value: "AL AWEER", label: "AL AWEER" },
      { value: "AL BARARI", label: "AL BARARI" },
      { value: "AL BARASHI", label: "AL BARASHI" },
      { value: "AL BARSHA", label: "AL BARSHA" },
      { value: "AL BARSHA SOUTH", label: "AL BARSHA SOUTH" },
      { value: "AL BASRA AL AIN", label: "AL BASRA AL AIN" },
      { value: "AL BURAIRAT", label: "AL BURAIRAT" },
      { value: "AL DAR AL BAIDA", label: "AL DAR AL BAIDA" },
      { value: "AL DHAFRA", label: "AL DHAFRA" },
      { value: "AL DHAFRA AB", label: "AL DHAFRA AB" },
      { value: "AL DHAFRA AIR BASE AE", label: "AL DHAFRA AIR BASE AE" },
      { value: "AL DHAIT", label: "AL DHAIT" },
      { value: "Dhaid", label: "Dhaid" },
      { value: "AL DHAIT NORTH", label: "AL DHAIT NORTH" },
      { value: "AL DHAIT SOUTH", label: "AL DHAIT SOUTH" },
      { value: "AL DIGDAGA", label: "AL DIGDAGA" },
      { value: "AL DIYAFA", label: "AL DIYAFA" },
      { value: "AL FALLAH", label: "AL FALLAH" },
      { value: "AL FURJAN", label: "AL FURJAN" },
      { value: "AL GARHOUD", label: "AL GARHOUD" },
      { value: "AL GHAIL INDUSTRIAL PARK", label: "AL GHAIL INDUSTRIAL PARK" },
      { value: "AL GHUSAIS", label: "AL GHUSAIS" },
      {
        value: "AL GHUSAIS INDUSTRIAL AREA",
        label: "AL GHUSAIS INDUSTRIAL AREA",
      },
      { value: "AL HADITHA", label: "AL HADITHA" },
      { value: "AL HAIL INDISTRIAL AREA", label: "AL HAIL INDISTRIAL AREA" },
      { value: "AL HAWIYAH", label: "AL HAWIYAH" },
      { value: "AL HAZZAN", label: "AL HAZZAN" },
      { value: "AL HILI AL AIN", label: "AL HILI AL AIN" },
      { value: "AL HUDAIBAH", label: "AL HUDAIBAH" },
      { value: "AL HUMRAH", label: "AL HUMRAH" },
      { value: "AL JADDAF", label: "AL JADDAF" },
      { value: "AL JAHLI AL AIN", label: "AL JAHLI AL AIN" },
      { value: "AL JIMI AL AIN", label: "AL JIMI AL AIN" },
      { value: "AL KHABISI AL AIN", label: "AL KHABISI AL AIN" },
      { value: "AL KHARRAN", label: "AL KHARRAN" },
      { value: "AL KHATEM", label: "AL KHATEM" },
      { value: "AL KHAWANEEJ", label: "AL KHAWANEEJ" },
      { value: "AL KHOR", label: "AL KHOR" },
      { value: "AL KHUBASI", label: "AL KHUBASI" },
      { value: "AL KHUZAM", label: "AL KHUZAM" },
      { value: "AL KUWAITAT AL AIN", label: "AL KUWAITAT AL AIN" },
      { value: "AL LAYAN", label: "AL LAYAN" },
      { value: "AL MADAR", label: "AL MADAR" },
      { value: "AL MAIDEN", label: "AL MAIDEN" },
      { value: "AL MAIRID", label: "AL MAIRID" },
      { value: "AL MAMOURAH", label: "AL MAMOURAH" },
      { value: "AL MANASEER AL AIN", label: "AL MANASEER AL AIN" },
      {
        value: "AL MARKAZ IND. PARK ABU DHABI",
        label: "AL MARKAZ IND. PARK ABU DHABI",
      },
      { value: "AL MARYAH ISLAND", label: "AL MARYAH ISLAND" },
      { value: "AL MISBAH AL AIN", label: "AL MISBAH AL AIN" },
      { value: "AL MNAIZFAH AL AIN", label: "AL MNAIZFAH AL AIN" },
      { value: "AL MNAIZLAH AL AIN", label: "AL MNAIZLAH AL AIN" },
      { value: "AL MURABBA AL AIN", label: "AL MURABBA AL AIN" },
      { value: "AL MUTAWAA AL AIN", label: "AL MUTAWAA AL AIN" },
      { value: "AL MUWAIJI AL AIN", label: "AL MUWAIJI AL AIN" },
      { value: "AL NAHDA 1", label: "AL NAHDA 1" },
      { value: "AL NAKEEL", label: "AL NAKEEL" },
      { value: "AL NAKHEEL", label: "AL NAKHEEL" },
      { value: "AL OWAINAH AL AIN", label: "AL OWAINAH AL AIN" },
      { value: "AL QUAWASIM CORNICHE", label: "AL QUAWASIM CORNICHE" },
      { value: "AL QUOZ", label: "AL QUOZ" },
      { value: "AL RAHA", label: "AL RAHA" },
      { value: "AL RAHA BEACH AREA", label: "AL RAHA BEACH AREA" },
      { value: "AL RAHMANIYA", label: "AL RAHMANIYA" },
      { value: "AL RAMLAH", label: "AL RAMLAH" },
      { value: "AL RAMS", label: "AL RAMS" },
      { value: "AL RAS", label: "AL RAS" },
      { value: "AL RAUDAH", label: "AL RAUDAH" },
      { value: "AL RIGGA", label: "AL RIGGA" },
      { value: "AL RIQQAH", label: "AL RIQQAH" },
      { value: "AL RUMAILAH AL AIN", label: "AL RUMAILAH AL AIN" },
      { value: "AL RUWAIKAH AL AIN", label: "AL RUWAIKAH AL AIN" },
      { value: "AL SAJJA", label: "AL SAJJA" },
      { value: "AL SALAMAH", label: "AL SALAMAH" },
      { value: "AL SANAIYA AL AIN", label: "AL SANAIYA AL AIN" },
      { value: "AL SAROOJ AL AIN", label: "AL SAROOJ AL AIN" },
      { value: "AL SHAREJ AL AIN", label: "AL SHAREJ AL AIN" },
      { value: "AL SILA", label: "AL SILA" },
      { value: "AL SOFOUH GARDENS", label: "AL SOFOUH GARDENS" },
      { value: "AL TAWAM AL AIN", label: "AL TAWAM AL AIN" },
      { value: "AL TOWAYYA AL AIN", label: "AL TOWAYYA AL AIN" },
      { value: "AL TWAR", label: "AL TWAR" },
      { value: "AL URAIBI", label: "AL URAIBI" },
      { value: "AL WAHA", label: "AL WAHA" },
    ],
    KW: [
      { value: "Abbasiya", label: "Abbasiya" },
      { value: "Abdally", label: "Abdally" },
      { value: "Abdulla Al Salem", label: "Abdulla Al Salem" },
      { value: "Abdullah Mubarak", label: "Abdullah Mubarak" },
      { value: "Abrak Kheitan", label: "Abrak Kheitan" },
      { value: "Abu Al Hasania", label: "Abu Al Hasania" },
      { value: "Abu Fatira", label: "Abu Fatira" },
      { value: "Abu Halifa", label: "Abu Halifa" },
      { value: "Adan", label: "Adan" },
      { value: "Adeliya", label: "Adeliya" },
      { value: "Ahmadi", label: "Ahmadi" },
      { value: "Ahmed Al Jaber Airbase", label: "Ahmed Al Jaber Airbase" },
      { value: "Al Abadaly", label: "Al Abadaly" },
      { value: "Al Ayoun", label: "Al Ayoun" },
      { value: "Al Bidaa", label: "Al Bidaa" },
      { value: "Al Funnayhil", label: "Al Funnayhil" },
      { value: "Al Masayeel", label: "Al Masayeel" },
      { value: "Al Naseem", label: "Al Naseem" },
      { value: "Al Qasar", label: "Al Qasar" },
      { value: "Al Rai", label: "Al Rai" },
      { value: "Al Taima", label: "Al Taima" },
      { value: "Al Waha", label: "Al Waha" },
      { value: "Ali Al Salem Air Base", label: "Ali Al Salem Air Base" },
      { value: "Ali Sabah Al Salem", label: "Ali Sabah Al Salem" },
      { value: "Amghara", label: "Amghara" },
      { value: "Andalus", label: "Andalus" },
      { value: "Ardiya", label: "Ardiya" },
      { value: "Ashwaq Qurain", label: "Ashwaq Qurain" },
      { value: "Bayan", label: "Bayan" },
      { value: "Bneid Al Gar", label: "Bneid Al Gar" },
      { value: "Camp Arifjan", label: "Camp Arifjan" },
      { value: "Camp Buehring", label: "Camp Buehring" },
      { value: "Daher", label: "Daher" },
      { value: "Daiya", label: "Daiya" },
      { value: "Dajeej", label: "Dajeej" },
      { value: "Darwaza", label: "Darwaza" },
      { value: "Dasma", label: "Dasma" },
      { value: "Dasman", label: "Dasman" },
      { value: "Doha", label: "Doha" },
      { value: "Egaila", label: "Egaila" },
      { value: "Fahad Al Ahmad", label: "Fahad Al Ahmad" },
      { value: "Fahaheel", label: "Fahaheel" },
      { value: "Faiha", label: "Faiha" },
      { value: "Farwaniya", label: "Farwaniya" },
      { value: "Fintas", label: "Fintas" },
      { value: "Firdous", label: "Firdous" },
      { value: "Funantees", label: "Funantees" },
      { value: "Garnata", label: "Garnata" },
      { value: "Hadiya", label: "Hadiya" },
      { value: "Hawally", label: "Hawally" },
      { value: "Hitteen", label: "Hitteen" },
      { value: "Ishbilya", label: "Ishbilya" },
      { value: "Jaber Al Ahmad", label: "Jaber Al Ahmad" },
      { value: "Jaber Al Ali", label: "Jaber Al Ali" },
      { value: "Jabriya", label: "Jabriya" },
      { value: "Jahra", label: "Jahra" },
      { value: "Jleeb Al Shuyoukh", label: "Jleeb Al Shuyoukh" },
      { value: "Juliah", label: "Juliah" },
      { value: "Kabd", label: "Kabd" },
      { value: "Keifan", label: "Keifan" },
      { value: "Khaldiya", label: "Khaldiya" },
      { value: "Kheiran", label: "Kheiran" },
      { value: "Kuwait City", label: "Kuwait City" },
      { value: "Mahboula", label: "Mahboula" },
      { value: "Maidan Hawally", label: "Maidan Hawally" },
      { value: "Maliya", label: "Maliya" },
      { value: "Mangaf", label: "Mangaf" },
      { value: "Mansouriya", label: "Mansouriya" },
      { value: "Messila", label: "Messila" },
      { value: "Mina Abdulla", label: "Mina Abdulla" },
      { value: "Mina Ahmadi", label: "Mina Ahmadi" },
      { value: "Mina Al Zour", label: "Mina Al Zour" },
      { value: "Mirgab", label: "Mirgab" },
      { value: "Mishref", label: "Mishref" },
      { value: "Mubarak Abdullah", label: "Mubarak Abdullah" },
      { value: "Mubarak Aj Jabar Suburb", label: "Mubarak Aj Jabar Suburb" },
      { value: "Mubarak Al Kabeer", label: "Mubarak Al Kabeer" },
      { value: "Mubarakiya", label: "Mubarakiya" },
      { value: "Nahda", label: "Nahda" },
      { value: "Nigra", label: "Nigra" },
      { value: "Nuzha", label: "Nuzha" },
      { value: "Omariya", label: "Omariya" },
      { value: "Qadsiya", label: "Qadsiya" },
      { value: "Qairawan", label: "Qairawan" },
      { value: "Qibla", label: "Qibla" },
      { value: "Qortuba", label: "Qortuba" },
      { value: "Qosoor", label: "Qosoor" },
      { value: "Qurain", label: "Qurain" },
      { value: "Rabiya", label: "Rabiya" },
      { value: "Rawdah", label: "Rawdah" },
      { value: "Rehab", label: "Rehab" },
      { value: "Rikka", label: "Rikka" },
      { value: "Rumaithiya", label: "Rumaithiya" },
      { value: "Saad Al Abdullah", label: "Saad Al Abdullah" },
      { value: "Sabah Al Ahmed", label: "Sabah Al Ahmed" },
      { value: "Sabah Al Naser", label: "Sabah Al Naser" },
      { value: "Sabah Al Salem", label: "Sabah Al Salem" },
      { value: "Sabahiya", label: "Sabahiya" },
      { value: "Sabhan", label: "Sabhan" },
      { value: "Sabiya", label: "Sabiya" },
    ],
  };
  const [cityOptions, setCityOptions] = useState([]);
  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setNewAddress({
      ...newAddress,
      selectedCountry,
      selectedCity: "",
    });

    // Update the city options based on the selected country
    setCityOptions(cityOptionsByCountry[selectedCountry] || []);
  };

  const [errors, setErrors] = useState({
    user_id: false,
    shipping_address_id: false,
    is_paid: false,
    status: false,
    selectedProducts: false,
    useUserPoints: false,
  });

  useEffect(() => {
    if (isOpen) {
      setNewOrderData({
        user_id: "",
        shipping_address_id: "",
        promocode_id: "",
        is_paid: "",
        status: "",
        selectedProducts: [],
      });
      setProductQuantities({});
      setHighlightedProducts([]);
      setShowNewAddressForm(false);
      setErrors({
        user_id: false,
        shipping_address_id: false,
        is_paid: false,
        status: false,
        selectedProducts: false,
      });
    }
  }, [isOpen]);

  const handleChange = (field, value) => {
    setNewOrderData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: false,
    }));
  };

  const handleProductChange = (selectedProducts) => {
    setNewOrderData((prevData) => ({
      ...prevData,
      selectedProducts,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      selectedProducts: false,
    }));
  };

  const handleQuantityChange = (productId, quantity) => {
    setProductQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: quantity,
    }));
  };

  const handleProductMenuOpen = () => {
    setHighlightedProducts(newOrderData.selectedProducts);
  };

  const handleProductMenuItemMouseEnter = (productId) => {
    if (!highlightedProducts.includes(productId)) {
      setHighlightedProducts((prevHighlighted) => [
        ...prevHighlighted,
        productId,
      ]);
    }
  };

  const handleProductMenuItemMouseLeave = (productId) => {
    if (!newOrderData.selectedProducts.includes(productId)) {
      setHighlightedProducts((prevHighlighted) =>
        prevHighlighted.filter((id) => id !== productId)
      );
    }
  };

  const handleCityChange = (selectedOption) => {
    console.log("newAddress", newAddress);
    setNewAddress({
      ...newAddress,
      selectedCity: selectedOption.target.value,
    });
  };

  const handleSubmit = () => {
    let hasErrors = false;
    const newErrors = {};

    Object.keys(newOrderData).forEach((field) => {
      if (
        !newOrderData[field] &&
        field !== "promocode_id" &&
        field !== "selectedProducts" &&
        !(field === "shipping_address_id" && showNewAddressForm)
      ) {
        hasErrors = true;
        newErrors[field] = true;
      }
    });

    if (newOrderData.selectedProducts.length === 0) {
      hasErrors = true;
      newErrors.selectedProducts = true;
    }

    if (showNewAddressForm) {
      if (!newAddress.first_name) {
        hasErrors = true;
        newErrors.newAddressFirstName = true;
      }
      if (!newAddress.last_name) {
        hasErrors = true;
        newErrors.newAddressLastName = true;
      }
      if (!newAddress.address) {
        hasErrors = true;
        newErrors.newAddress = true;
      }
      if (!newAddress.selectedCity) {
        hasErrors = true;
        newErrors.selectedCity = true;
      }
      if (!newAddress.selectedCountry) {
        hasErrors = true;
        newErrors.selectedCountry = true;
      }
      if (!newAddress.phone) {
        hasErrors = true;
        newErrors.newAddressPhone = true;
      }
    }

    if (hasErrors) {
      setErrors(newErrors);
    } else {
      const orderDataWithQuantities = {
        ...newOrderData,
        productQuantities,
        useUserPoints,
        deliveryFees,
        newAddress: showNewAddressForm ? newAddress : null,
      };
      onSubmit(orderDataWithQuantities);
      onClose();
      setNewOrderData({
        user_id: "",
        shipping_address_id: "",
        promocode_id: "",
        is_paid: "",
        status: "",
        selectedProducts: [],
      });
      setProductQuantities({});
      setHighlightedProducts([]);
      setErrors({
        user_id: false,
        shipping_address_id: false,
        is_paid: false,
        status: false,
        selectedProducts: false,
      });
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
          error={errors.user_id}
        >
          <InputLabel>User *</InputLabel>
          <Select
            value={newOrderData.user_id}
            onChange={(e) => handleChange("user_id", e.target.value)}
            label="Select User"
          >
            <MenuItem value={"guest"}> Guest </MenuItem>

            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.first_name} {user.last_name} {user.phone}
              </MenuItem>
            ))}
          </Select>
          {errors.user_id && (
            <FormHelperText style={{ color: "red" }}>Required</FormHelperText>
          )}
        </FormControl>

        {newOrderData.user_id && newOrderData.user_id !== "guest" && (
          <FormControl fullWidth margin="normal" variant="outlined">
            <FormControlLabel
              control={
                <Switch
                  checked={useUserPoints}
                  onChange={() => setUseUserPoints(!useUserPoints)}
                  color="success"
                />
              }
              label="Use User Points"
              labelPlacement="start"
              style={{ marginLeft: 0, marginRight: "auto" }}
            />
          </FormControl>
        )}

        <div style={{ display: "flex", alignItems: "center" }}>
          {!showNewAddressForm ? (
            <>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
                error={errors.shipping_address_id}
              >
                <InputLabel>Shipping Address *</InputLabel>
                <Select
                  value={newOrderData.shipping_address_id}
                  onChange={(e) =>
                    handleChange("shipping_address_id", e.target.value)
                  }
                  label="Select Shipping Address"
                >
                  {shippingAddresses.map((address) => (
                    <MenuItem key={address.id} value={address.id}>
                      {address.first_name} {address.last_name} -{" "}
                      {address.address} - {address.phone}
                    </MenuItem>
                  ))}
                </Select>
                {errors.shipping_address_id && (
                  <FormHelperText style={{ color: "red" }}>
                    Required
                  </FormHelperText>
                )}
              </FormControl>
              <IconButton
                onClick={() => setShowNewAddressForm(true)}
                style={{ marginLeft: 8 }}
              >
                <AddIcon />
              </IconButton>
            </>
          ) : (
            <div style={{ width: "100%" }}>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                label="First Name"
                value={newAddress.first_name}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, first_name: e.target.value })
                }
                required
              />
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                label="Last Name"
                value={newAddress.last_name}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, last_name: e.target.value })
                }
                required
              />
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>Select Country</InputLabel>

                <Select
                  value={newAddress.selectedCountry}
                  onChange={handleCountryChange}
                  label="Select Country"
                >
                  {countryOptions.map((country) => (
                    <MenuItem key={country.value} value={country.value}>
                      {country.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.selectedProducts && (
                  <FormHelperText style={{ color: "red" }}>
                    Required
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>Select City</InputLabel>
                <Select
                  value={newAddress.selectedCity}
                  onChange={handleCityChange}
                  label="Select City"
                  disabled={!newAddress.selectedCountry}
                >
                  {cityOptions.map((city) => (
                    <MenuItem key={city.value} value={city.value}>
                      {city.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                label="Address"
                value={newAddress.address}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, address: e.target.value })
                }
                required
              />
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                label="Phone"
                value={newAddress.phone}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, phone: e.target.value })
                }
                required
              />
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                label="Email"
                value={newAddress.email}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, email: e.target.value })
                }
              />
              <Button
                onClick={() => setShowNewAddressForm(false)}
                variant="outlined"
                style={{ marginTop: 8 }}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>

        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
          error={errors.selectedProducts}
        >
          <InputLabel>Products *</InputLabel>
          <Select
            multiple
            value={newOrderData.selectedProducts}
            onChange={(e) => handleProductChange(e.target.value)}
            onOpen={handleProductMenuOpen}
            label="Select Products"
          >
            {products.map((product) => (
              <MenuItem
                key={product.id}
                value={product.id}
                onMouseEnter={() => handleProductMenuItemMouseEnter(product.id)}
                onMouseLeave={() => handleProductMenuItemMouseLeave(product.id)}
                style={{
                  backgroundColor: highlightedProducts.includes(product.id)
                    ? "rgba(169, 169, 169, 0.5)"
                    : "transparent",
                }}
              >
                {product.name_en} {product.price} SAR
              </MenuItem>
            ))}
          </Select>
          {errors.selectedProducts && (
            <FormHelperText style={{ color: "red" }}>Required</FormHelperText>
          )}
        </FormControl>

        {/* Display quantity input for each selected product */}
        {newOrderData.selectedProducts.map((productId) => (
          <TextField
            key={productId}
            fullWidth
            margin="normal"
            variant="outlined"
            label={`Quantity for ${
              products.find((p) => p.id === productId)?.name_en
            }`}
            value={productQuantities[productId] || ""}
            onChange={(e) => handleQuantityChange(productId, e.target.value)}
            error={!productQuantities[productId]}
            helperText={!productQuantities[productId] && "Required"}
          />
        ))}

        {!useUserPoints && (
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Promo Code</InputLabel>
            <Select
              value={newOrderData.promocode_id || ""}
              onChange={(e) => handleChange("promocode_id", e.target.value)}
              label="Select Promo Code"
            >
              {promoCodes.map((promo) => (
                <MenuItem key={promo.id} value={promo.id}>
                  {promo.code} - {promo.value}% off
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel>Is Paid *</InputLabel>
          <Select
            value={newOrderData.is_paid}
            onChange={(e) => handleChange("is_paid", e.target.value)}
            label="Select Is Paid"
          >
            <MenuItem value="1">Paid</MenuItem>
            <MenuItem value="0">Unpaid</MenuItem>
          </Select>
          {errors.is_paid && (
            <FormHelperText style={{ color: "red" }}>Required</FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel>Status *</InputLabel>
          <Select
            value={newOrderData.status}
            onChange={(e) => handleChange("status", e.target.value)}
            label="Select Status"
          >
            <MenuItem value="1">Pending</MenuItem>
            <MenuItem value="2">Approved</MenuItem>
            <MenuItem value="3">Shipped</MenuItem>
            <MenuItem value="4">Received</MenuItem>
            <MenuItem value="5">Rejected</MenuItem>
            <MenuItem value="6">Returned Damaged</MenuItem>
            <MenuItem value="7">Returned</MenuItem>
            <MenuItem value="8">Cancel</MenuItem>
          </Select>
          {errors.status && (
            <FormHelperText style={{ color: "red" }}>Required</FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth margin="normal" variant="outlined">
          <FormControlLabel
            control={
              <Switch
                checked={deliveryFees}
                onChange={() => setDeliveryFees(!deliveryFees)}
                color="success"
              />
            }
            label="10 SAR Delivery fees"
            labelPlacement="start"
            style={{ marginLeft: 0, marginRight: "auto" }}
          />
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          style={{
            color: "#ffd700",
            borderColor: "#ffd700",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="outlined"
          style={{
            color: "#ffd700",
            borderColor: "#ffd700",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          Add Order
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrderDialog;
