import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import Loader from "react-js-loader";
import api from "state/api";
import "./sms.css";

const SMS = () => {
  const [processedData, setProcessedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectionType, setSelectionType] = useState("phoneNumbers");
  const [selectedRecipients, setSelectedRecipients] = useState(null);
  const [messageText, setMessageText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.getSMS();

        const phoneNumberOptions = response.shippingAddress.map((address) => ({
          value: address.id,
          label: `${address.first_name} ${address.last_name} (${address.phone})`,
          phone: address.phone,
          country: address.country,
        }));

        const phoneNumbersByCountry = phoneNumberOptions.reduce(
          (acc, { country, phone }) => {
            if (!acc[country]) acc[country] = [];
            acc[country].push(phone);
            return acc;
          },
          {}
        );

        setProcessedData({
          phoneNumberOptions,
          phoneNumbersByCountry,
          countryOptions: response.countryOptions,
          peopleWithOrdersCount: response.peopleWithOrders.count,
          peopleWithOrders: response.peopleWithOrders.details.map((person) => ({
            value: person.id,
            label: `${person.first_name} ${person.last_name} (${person.phone})`,
            phone: person.phone,
          })),
          peopleWithoutOrdersCount: response.peopleWithoutOrders.count,
          peopleWithoutOrders: response.peopleWithoutOrders.details.map(
            (person) => ({
              value: person.id,
              label: `${person.first_name} ${person.last_name} (${person.phone})`,
              phone: person.phone,
            })
          ),
        });
      } catch (error) {
        console.log("Error fetching SMS data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSelectionChange = (selectionType) => {
    setSelectionType(selectionType);
    if (selectionType === "withOrders") {
      setSelectedRecipients(processedData.peopleWithOrders);
    } else if (selectionType === "withoutOrders") {
      setSelectedRecipients(processedData.peopleWithoutOrders);
    } else {
      setSelectedRecipients(null);
    }
  };

  const handleSendSMS = async () => {
    if (!messageText || !selectedRecipients) {
      alert("Please enter a message and select recipients.");
      return;
    }

    let phoneNumbers = [];

    if (selectionType === "phoneNumbers") {
      phoneNumbers = selectedRecipients
        .map(
          (recipient) =>
            processedData.phoneNumberOptions.find(
              (option) => option.value === recipient.value
            )?.phone
        )
        .filter(Boolean);
    } else if (selectionType === "country") {
      const selectedCountry = selectedRecipients.value;
      phoneNumbers = processedData.phoneNumbersByCountry[selectedCountry] || [];
    } else if (selectionType === "withOrders") {
      phoneNumbers = processedData.peopleWithOrders.map(
        (person) => person.phone
      );
    } else if (selectionType === "withoutOrders") {
      phoneNumbers = processedData.peopleWithoutOrders.map(
        (person) => person.phone
      );
    }

    if (phoneNumbers.length === 0) {
      alert("No valid phone numbers found.");
      return;
    }

    const recipients = phoneNumbers.join(", ");
    const data = {
      messageText: messageText,
      recipients: recipients,
    };

    try {
      const response = await api.sendSMS(data);

      if (response) {
        alert("SMS request sent successfully!");
        console.log("SMS response:", response);
      }
    } catch (error) {
      console.error("Error sending SMS request:", error);
      alert("Failed to send SMS request.");
    }
  };

  if (loading) {
    return (
      <div className="loader-container">
        <Loader type="spinner-cub" bgColor="gold" color="gold" size={100} />
      </div>
    );
  }

  return (
    <div className="sms-container">
      <h2>Send Bulk SMS</h2>

      <FormControl component="fieldset" style={{ marginBottom: "16px" }}>
        <RadioGroup
          row
          value={selectionType}
          onChange={(e) => handleSelectionChange(e.target.value)}
        >
          <FormControlLabel
            value="phoneNumbers"
            control={<Radio />}
            label="Select by Phone Numbers"
          />
          <FormControlLabel
            value="country"
            control={<Radio />}
            label="Select by Country"
          />
          <FormControlLabel
            value="withOrders"
            control={<Radio />}
            label={`Users with Orders (${processedData.peopleWithOrdersCount} contacts)`}
          />
          <FormControlLabel
            value="withoutOrders"
            control={<Radio />}
            label={`Users with Shipping Address but No Orders (${processedData.peopleWithoutOrdersCount} contacts)`}
          />
        </RadioGroup>
      </FormControl>

      {selectionType === "phoneNumbers" ? (
        <Select
          isMulti
          options={processedData.phoneNumberOptions}
          value={selectedRecipients}
          onChange={(selectedOptions) =>
            setSelectedRecipients(selectedOptions || [])
          }
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="Select Phone Numbers"
        />
      ) : selectionType === "country" ? (
        <Select
          options={processedData.countryOptions.map((country) => ({
            value: country,
            label: country,
          }))}
          value={selectedRecipients}
          onChange={(selectedOption) => setSelectedRecipients(selectedOption)}
          className="basic-select"
          classNamePrefix="select"
          placeholder="Select Country"
        />
      ) : selectionType === "withOrders" ? (
        <Select
          isMulti
          options={processedData.peopleWithOrders}
          value={selectedRecipients}
          onChange={(selectedOptions) =>
            setSelectedRecipients(selectedOptions || [])
          }
          className="basic-multi-select"
          classNamePrefix="select"
        />
      ) : (
        <Select
          isMulti
          options={processedData.peopleWithoutOrders}
          value={selectedRecipients}
          onChange={(selectedOptions) =>
            setSelectedRecipients(selectedOptions || [])
          }
          className="basic-multi-select"
          classNamePrefix="select"
        />
      )}

      <TextField
        fullWidth
        value={messageText}
        onChange={(e) => setMessageText(e.target.value)}
        placeholder="Enter your message here..."
        multiline
        rows={4}
        margin="normal"
        variant="outlined"
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleSendSMS}
        style={{ marginTop: "16px" }}
      >
        Send SMS
      </Button>
    </div>
  );
};

export default SMS;
